<script setup lang="ts">
    import type { WidgetContainerData } from '~/@types/cms';
    import type { IWidgetProps } from '~/composables/widgets';

    const props = defineProps<IWidgetProps<WidgetContainerData>>();
    const { data } = useWidget(props);
    const size = computed(() => data.style?.size);
    const componentSequence = ref<SequenceElement[]>([]);
</script>

<template>
    <atm-container
        tag="section"
        class="cms-widget-container">
        <cms-content-controller
            :data="data.value"
            :class="[
                'flex flex-col gap-6',
                data.display,
                {
                    'grid-cols-2 md:grid xl:grid xl:grid-cols-4': data.display === 'cols' && data.value.components.length === 4,
                    flex: data.display === 'rows',
                    'grid-cols-2 md:grid': data.display === 'grid2' || (data.value.components.length === 2 && data.display === 'cols'),
                    'md:grid md:grid-cols-3':
                        ['grid3', 'grid3_slider_mobile'].includes(data.display) || (data.value.components.length === 3 && data.display === 'cols'),
                    'items-center justify-center': size === 'text',
                    'max-lg:hidden': data.display === 'grid3_slider_mobile',
                },
            ]"
            @componentSequence="(data) => (componentSequence = data)" />
        <lazy-org-paginated-slider
            v-if="data.display === 'grid3_slider_mobile' && componentSequence.length"
            class="py-8 md:py-10 lg:hidden"
            :slide-data="componentSequence"
            v-slot="{ data, index }">
            <renderer-cms-component
                :cms-component="data"
                :index="index" />
        </lazy-org-paginated-slider>
    </atm-container>
</template>

<style scoped>
    .grid2 > *,
    .grid3 > * {
        @apply h-full w-full items-center rounded bg-woom-grey px-4 py-8 text-center;
    }

    .cols > div {
        @apply mx-auto my-0 max-w-[282px];
    }

    /* hint text block */
    .cols .text-box p,
    .text-box p {
        @apply flex px-8 py-2;
    }
</style>
